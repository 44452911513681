import React from 'react'
import topimg from '../assets/bg/image1.jpg'
import img1 from '../assets/bg/listtype/spring-flush.png'
import img2 from '../assets/bg/listtype/summer-flush.png'
import img3 from '../assets/bg/listtype/monsoon-flush.png'
import img4 from '../assets/bg/listtype/autumn-flush.png'

import imgs1 from '../assets/bg/listtype/more-cups-new.gif'
import imgs2 from '../assets/bg/listtype/whiteTea.jpg'
import imgs3 from '../assets/bg/listtype/greenTea.jpg'
import imgs4 from '../assets/bg/listtype/oolongTea.jpg'

import toppictea from '../assets/toppictea.jpg'
import { teaCategoriesData } from './teadata/Teatypedata'
import { Link } from 'react-router-dom';
// import Explore from './tools/Explore'
import Whatsappwidget from './tools/Whatsappwidget';

export default function Top() {


    return (
        <div className='top'>

            <div className="imagewithtxt">
                <img src={topimg} alt="topimg" className="topimg" />
                {/* <div className="sidecnt">
                    <img src={toppictea} alt="toppictea" className='toppictea' />
                    <div className="ptxt bd">
                        <div className="small">our</div>
                        <div className="big">vision</div>
                    </div>
                </div> */}
                <div className="right">

                    <div className="top">
                        <div className="txt bd">
                            Buy Asian Tea Online

                        </div>
                    </div>

                    <div className="bottom">
                        <div className="toptxt mn3">Distributors of Tea</div>
                        <div className="smtxt">
                            Tea Asia is an international retailer and wholesaler of fine Asian tea.
                            We connect fresh tea directly with YOU, while cutting out multiple layers of middle men.
                            Now Available Worldwide.
                        </div>
                    </div>

                </div>
            </div>


            {/* <div className="txtwithbtns mn3">
                <div className="topdesc bd">
                    ABOUT TEMI TEA
                </div>
                <p className='homeabttxt'>
                    <div class="text">Temi Estate, founded in 1969, offers a delightful range of exclusive flavors to discerning tea enthusiasts. Recognized as an Organic Tea Estate in 2008, it has garnered accolades from across the globe.
                        Renowned for its exceptional quality, this collection of teas, grown at high elevations, has carved out a distinguished reputation.</div>

                    <br />
                    <span class="br"></span>
                    <br />
                    <span class="br"></span>
                    <div class="text">The Tea Estate's unique landscape imparts an unparalleled character to every sip.
                        Situated at elevations spanning from 1400 to 2000 meters above sea level, each season at Temi Tea Estate bestows a distinct character to every harvest.
                        From its inception to the present day, Temi takes great pride in preserving its heritage, distinctive essence, and captivating aroma.</div>

                    <br />
                    <span class="br"></span>
                </p>
                </div> */}
            {/* <div className="grnbtn btn">
                    GET A QUOTE
                </div>
                <div className="whitebtn btn">
                    SCHEDULE A MEETING
                </div> */}


            {/* <Explore /> */}
            {/* 
            <div className="teatypes">
              
                <div className="
                sikkimonlyteacatcnt
                 mn3" id='products'>
                    {teaCategoriesData.map(({ title, img, id, category }) => (
                        <Link to={`/category/${category}`} className="teabox" id={id}

                        >

                            <div className="imgcntteabox"
                            // onMouseEnter={() => setHover(true)}
                            // onMouseLeave={() => setHover(false)}
                            >

                                <img src={img} className="teacategoryimg" alt='teacategory' />


                            </div>

                            <p className='teacattitle'>{title}</p>
                        </Link>
                    )

                    )}

                </div>
            </div>
            <Whatsappwidget /> */}

            <div className="blackgoldlistarea">
                <div className="entry">

                    <div className="lr lrright">

                        <div className="left">

                            <div className="top">
                                <div className="txt bd">
                                    SPRING
                                </div>
                            </div>

                            <div className="bottom">
                                <div className="toptxt mn3">THE FIRST FLUSH</div>
                                <div className="smtxt">
                                    The first flush in tea refers to the initial harvest of tea leaves in a growing season, typically in spring. These tender, young leaves are prized for their delicate flavor and vibrant aroma. They are plucked by skilled tea pickers and carefully processed to preserve their freshness. First flush teas are known for their light, floral notes, and a subtle sweetness, making them highly sought after by tea connoisseurs. This early harvest captures the essence of the season and showcases the unique terroir of the tea-growing region.
                                </div>
                            </div>

                        </div>

                        <div className="right">
                            <img src={img1} alt="img1" />
                        </div>
                    </div>

                    <div className="lr lrleft">

                        <div className="left">

                            <div className="top">
                                <div className="txt bd">
                                    SUMMER
                                </div>
                            </div>

                            <div className="bottom">
                                <div className="toptxt mn3">THE SECOND FLUSH</div>
                                <div className="smtxt">
                                    The second flush in tea is the harvest that follows the first flush, typically occurring in late spring or early summer. During this period, tea leaves have matured, developing richer flavors and deeper colors. The most renowned second flush tea is Darjeeling, celebrated for its full-bodied, muscatel flavor, and a hint of astringency. These teas are often characterized by their amber liquor and are highly prized for their complexity and unique taste profile. Second flush teas offer a delightful contrast to the delicate first flush varieties.
                                </div>
                            </div>

                        </div>

                        <div className="right">
                            <img src={img2} alt="img2" />
                        </div>
                    </div>



                    <div className="lr lrright">

                        <div className="left">

                            <div className="top">
                                <div className="txt bd">
                                    MONSOON
                                </div>
                            </div>

                            <div className="bottom">
                                <div className="toptxt mn3">THE THIRD FLUSH</div>
                                <div className="smtxt">
                                    The goodness of a romantic Himalayan monsoon defines this range. Plucked from rain-sodden slopes, the succulent tea leaves are withered and oxidized to the season's best, to create a full body cup with mellow taste. What’s more, the heavy showers make it, all the way, matchless.
                                </div>
                            </div>

                        </div>

                        <div className="right">
                            <img src={img3} alt="img1" />
                        </div>
                    </div>

                    <div className="lr lrleft">

                        <div className="left">

                            <div className="top">
                                <div className="txt bd">
                                    AUTUMN
                                </div>
                            </div>

                            <div className="bottom">
                                <div className="toptxt mn3">THE FINAL FLUSH</div>
                                <div className="smtxt">
                                    Autumn is a warm welcome in winter. Plucked from the glorious midst of full-bloomed cherry blossom trees, it is the perfect way to soothe oneself from the nip of a Himalayan winter. With a well-rounded taste and the light hint of warm spices, this amber liquor is the perfect end to the seasons of tea.
                                </div>
                            </div>

                        </div>

                        <div className="right">
                            <img src={img4} alt="img4" />
                        </div>
                    </div>



                </div>
            </div>

            <div className="teaonebelowarea">

                <div className="imgholdcnt">
                    <img src={imgs1} alt="imgs1" />
                    <div className="txt">
                        <div className="sm bd">more</div>
                        <div className="big bd">cups of tea</div>
                    </div>
                </div>


                <div className="entryrowcnt">
                    <div className="txtarea">
                        <div className="top bd">WHITE TEA

                        </div>
                        <div className="bottom"> Made from buds and unfurled new leaves, the white tea from Temi is among the most delicate of a varieties, from around the world.
                        </div>
                    </div>

                    <img src={imgs2} alt="imgs2" />
                </div>

                <div className="entryrowcnt">
                    <div className="txtarea">
                        <div className="top bd">GREEN TEA


                        </div>
                        <div className="bottom"> Known for it's distinct steam distillation method, the green tea from Temi can relished as a flowery liquor.

                        </div>
                    </div>

                    <img src={imgs3} alt="imgs3" />
                </div>

                <div className="entryrowcnt">
                    <div className="txtarea">
                        <div className="top bd">OOLONG TEA



                        </div>
                        <div className="bottom"> The semi fermented range of tea from Temi is fruity, fragant, earthy; and famed for the wonders it does to one's well-being.


                        </div>
                    </div>

                    <img src={imgs4} alt="imgs4" />
                </div>

            </div>

        </div>
    )
}
