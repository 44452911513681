import React from 'react'
import { IconContext } from 'react-icons'
import { AiFillMail, AiOutlineWhatsApp } from 'react-icons/ai'
import { FaTelegramPlane } from 'react-icons/fa'
// import { Link } from "react-scroll";

export default function Footer() {
    return (
        <div className='footer'>
            <div className="row1 row mn4" id='footer'>
                At Tea-Asia we bring you the
                <br />
                highest quality teas, and tisanes<br />
                from all across Asia.<br />
                {/* <br />
                Tea-Asia LLC<br />
                Darjeeling,
                <br />
                West Bengal,
                <br />
                India<br /> */}
                <br />
                {/* +91 8250-195857<br /> */}
                teaasiacom@gmail.com<br />
                <br />
            </div>

            {/* <div className="row2 row mn2">
                <div className="fthd">Links</div>
                <div className="footerlinks">

                    <a href='/'>Home</  a>
                    <a href="/category/darjeeling">
                      //   <Link
                      //      activeClass="active"
                      //      to="products"
                      //      spy={true}
                      //      smooth={true}
                      //      offset={0}
                      //      duration={1000}
                      //  >Products</Link> 
Products
                    </a>
                    <a href='/about'>About us</a>
                

                    <a href=''>
                        <Link
                            activeClass="active"
                            to="footer"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={1000}
                        >Contact us
                        </Link>
                    </a>

                </div>
            </div>
 */}




            <div className="row3 row mn2"><div className="fthd">
                {/* Social Networks */}
                <IconContext.Provider value={{ size: "1.8rem" }}>
                    <div className="smicons">
                        {/* <a href="">
                            <AiOutlineTwitter />

                        </a>
                        <a href="">
                            <AiFillInstagram />

                        </a> */}
                        <a href="mailto:teaasiacom@gmail.com">
                            <AiFillMail />

                        </a>
                        <a
                            href="https://wa.me/918250195857?text=Regarding%20services%20of%20tea%20asia,%20"
                        >
                            <AiOutlineWhatsApp />

                        </a>
                        <a href="https://telegram.me/tea_asia">
                            <FaTelegramPlane />

                        </a>


                    </div>
                </IconContext.Provider>

            </div>
            </div>
        </div>
    )
}
