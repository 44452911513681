import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.scss';
import App from './App';
import Tealist from './components/routes/Tealist';
import Teaproduct from './components/routes/Teaproduct';
import About from './components/routes/About';
import ScrollToTop from "./components/helpers/ScrollToTop";
import Blog from './components/routes/Blog';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "category/:category",
    element: <Tealist />
  },
  // {
  //   path: "category/:category/type/:slug",
  //   element: <Tealist />
  // },
  {
    path: "category/:category/:slug",
    element: <Teaproduct />
  },
  {
    path: "about",
    element: <About />
  },
  {
    path: "blog",
    element: <Blog />
  },
  {
    path: "*",
    element: <App />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}   />

  </React.StrictMode>
);

