import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../Footer'
import Navbar from '../Navbar'
import { teaproducts } from '../teadata/Teatypedata'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { TiTick } from 'react-icons/ti'
import { IconContext } from 'react-icons'
import { AiFillMail, AiOutlineWhatsApp } from 'react-icons/ai'
import { FaTelegramPlane } from 'react-icons/fa'

// import Explore from '../tools/Explore'
import Whatsappwidget from '../tools/Whatsappwidget'
export default function Teaproduct() {
    const { slug } = useParams()

    const currentProduct = teaproducts.find(prod => prod.slug === slug)

    const form = useRef();


    // EMAILJS DETAILS HERE, SERVICE ID, CLIENT ID, USER ID
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            'service_9hzqgbp',
            'template_fem9m9r',
            form.current,
            '2U6zxPgwdRX2D-rqW'
        )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        alert("Thank you for reaching out to us! we will get back to you shortly.")
    }


    return (
        <>
            <Navbar />

            <div className='teaproduct mn2'>
                <div className="currentarea">
                    {/* <a href="../">Home&nbsp;</a> */}

                    {`>`} Products  {`>`} Tea {`>`} {currentProduct.category} {`>`} {currentProduct.title}
                </div>
                <div className="e3">
                    {/* <Explore /> */}

                </div>

                <div className="tophalf mn3" id='products'>
                    <img src={currentProduct.img} className="teaimg" alt='tea' />

                    <p className='prodtitle mn5'>{currentProduct.title}</p>
                    <div className="threelines">
                        <ul className="title">
                            Item
                            <li className="item item1">{currentProduct.productname}</li>
                            <li className="item">{currentProduct.productname}</li>
                        </ul>
                        <ul className="title">
                            size/gm
                            <li className="item item1">{currentProduct.size1}</li>
                            <li className="item">{currentProduct.size2}</li>
                        </ul>
                    </div>

                </div>


                <div className="bottomhalf mn3">



                    <form ref={form} onSubmit={sendEmail} className="form">
                        <button type='submit' className="grnbtn" ref={form} onSubmit={sendEmail}>GET A QUOTE</button>
                        <div className="looking"> <p className="norm">Looking for</p>  <p className="grn">"{currentProduct.title}"</p> </div>



                        <div className="hidden">

                            <input type="title" className='title' placeholder={currentProduct.title} value={currentProduct.title} name="product" required />

                            <input type="prodtitle" className='prodtitle' placeholder={currentProduct.productname} value={currentProduct.productname} name="productname" required />
                            <input type="prodtitle" className='prodtitle' placeholder={currentProduct.productname} value={currentProduct.category} name="type" required />
                        </div>



                        <div className="twoblock">
                            <input type="text" className='name' placeholder='Name*' name="name" required />
                            <input type="email" className='email' placeholder='Email*' name="email" required />
                        </div>
                        <div className="twoblock">
                            <input type="number" className='phoneno' placeholder='Phone no.' name="number" />
                            <input type="text" className='ordval' placeholder='Approx order value' name="ordval" />
                        </div>
                        <div className="twoblock2">
                            <input type="text" className='quant' placeholder='Estimated Quantity' name="quantity" required />
                            <select name="unittype" className='unittype'>

                                <option value="kg">Unit type : Kg</option>
                                <option value="gm">Unit type : Gm</option>
                                <option value="mg">Unit type : Mg</option>

                            </select>
                        </div>




                        <p className='purposereq'>Purpose of requirement</p>


                        <div className="tickline">
                            <input id="item1" type="checkbox" name="EndUse" value='end use' />
                            <label for="item1">
                                End Use <TiTick />
                            </label><br />

                            <input id="item2" type="checkbox" name="Reselling" value='reselling' />
                            <label for="item2">Reselling <TiTick /></label><br />

                            <input id="item3" type="checkbox" name="Rawmaterial" value='raw material' />
                            <label for="item3">Raw Material <TiTick /></label><br />
                        </div>




                        <textarea id='message' rows='4' placeholder='Requirement details' name="details" />
                        <button type='submit'>SEND ENQUIRY</button>

                        <div className="contactusarea">
                            <div className="contustxt">
                                <p className="norm">Looking to skip the form and</p>
                                  <span className="grn">"Contact us?"</span>
</div>
                                    <IconContext.Provider value={{ size: "1.7rem" }}>
                                        <div className='rowico'>

                                            <a href="mailto:teaasiacom@gmail.com">
                                                <AiFillMail />

                                            </a>
                                            <a
                                                href="https://wa.me/918250195857?text=Regarding%20services%20of%20tea%20asia,%20"
                                            >
                                                <AiOutlineWhatsApp />

                                            </a>
                                            <a href="https://telegram.me/tea_asia">
                                                <FaTelegramPlane />

                                            </a>


                                        </div>
                                    </IconContext.Provider>
                          



                        </div>
                    </form>


                </div>
                <Whatsappwidget />

            </div>
            <Footer />

        </>

    )
}
