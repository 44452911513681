// import darjeelingimg from '../../assets/darjeeling.png'


// import tibetianteaimg from '../../assets/tibetian/tibetiantea.png'


// import mal from '../../assets/darjeeling/mal.png'
// import cham from '../../assets/darjeeling/cham.png'
// import sib from '../../assets/darjeeling/sib.png'
// import moondrop from '../../assets/darjeeling/moondrop.png'
// import whitetea from '../../assets/darjeeling/whitetea.png'


import blktea from '../../assets/sikkim/blktea.png'
import broknpackt from '../../assets/sikkim/broknpackt.png'
import grntea from '../../assets/sikkim/grntea.png'
import silvertea from '../../assets/sikkim/silvertea.jpg'
import grnwoodcaddy from '../../assets/sikkim/grnwoodcaddy.png'
import sum from '../../assets/sikkim/sum.png'
import mon from '../../assets/sikkim/mon.png'
import aut from '../../assets/sikkim/aut.png'
import awak from '../../assets/sikkim/awak.png'
import whitetea from '../../assets/sikkim/whitetea.jpg'
import springcaddy from '../../assets/sikkim/springcaddy.jpg'
import ooglongtea from '../../assets/sikkim//ooglongtea.jpg'

// INHERE

import spring from '../../assets/temitea/spring.png'
import broken from '../../assets/temitea/broken.png'

import firstflushpacket from '../../assets/temitea/firstflushpacket.png'
import woodencaddyblk from '../../assets/temitea/woodencaddyblk.png'
import blackdips from '../../assets/temitea/blackdips.jpg'
import greendips from '../../assets/temitea/greendips.jpg'
import whiteteabox from '../../assets/temitea/whitetea.jpg'
import gingerdips from '../../assets/temitea/gingerdips.jpg'
import lemondips from '../../assets/temitea/lemondips.jpg'
import temioolongdips from '../../assets/temitea/temioolongdips.jpg'
import special from '../../assets/temitea/special.png'
import dustpouch from '../../assets/temitea/dustpouch.png'
import ooglongtea2 from '../../assets/temitea/oolongtea.jpeg'
import FIRSTFLUSH from '../../assets/temitea/FIRSTFLUSH.jpg'
import GREENTEA from '../../assets/temitea/GREENTEA.jpg'
import DUST from '../../assets/temitea/DUST.PNG'
import BLACKTEA from '../../assets/temitea/BLACKTEA.PNG'
import silverneedles from '../../assets/temitea/silverneedles.jpg'
import placeholdersoon from '../../assets/sikkim//placeholder2.png'

// import summondip from '../../assets/sikkim/summondip.png'

import p25 from '../../assets/sikkim/p25.webp';
import p26 from '../../assets/sikkim/p26.webp';
import p27 from '../../assets/sikkim/p27.webp';
import p28 from '../../assets/sikkim/p28.webp';
import p29 from '../../assets/sikkim/p29.webp';
import p30 from '../../assets/sikkim/p30.webp';
import p31 from '../../assets/sikkim/p31.webp';
import p32 from '../../assets/sikkim/p32.webp';
import p33 from '../../assets/sikkim/p33.webp';
import p34 from '../../assets/sikkim/p34.webp';
import p35 from '../../assets/sikkim/p35.webp';
import p36 from '../../assets/sikkim/p36.webp';
import p37 from '../../assets/sikkim/p37.webp';
import p38 from '../../assets/sikkim/p38.webp';
import p39 from '../../assets/sikkim/p39.webp';
import p40 from '../../assets/sikkim/p40.webp';
import p41 from '../../assets/sikkim/p41.webp';
import p42 from '../../assets/sikkim/p42.webp';
import p43 from '../../assets/sikkim/p43.webp';
import bluepea from '../../assets/sikkim/bluepea.png';
import rosetea from '../../assets/sikkim/rosetea.jpg';
import marigoldtea from '../../assets/sikkim/marigoldtea.jpg';

import easybrewbox from '../../assets/sikkim/easybrewbox.png';

export const teaCategoriesData = [
  // hidden
  {
    title: "Darjeeling",
    // img: darjeelingimg,
    id: 1,
    category: "darjeeling"
  },
  {
    title: "Sikkim",
    // img: darjeelingimg,
    id: 2,
    category: "sikkim"
  },
  // {
  //     title: "Tibetan",
  //     img: darjeelingimg,
  //     id: 3,
  //     category: "tibetan"
  // },
  // {
  //     title: "Assam",
  //     img: darjeelingimg,
  //     id: 4,
  //     category: "assam",
  // },


]


export const teaproducts = [

  {
    title: "Silver Tea",
    img: silvertea,
    id: 8,
    size1: "Silver Tea - 125",
    size2: "Silver Tea - 250",
    slug: "silver_tea",
    productname: "Silver Tea",
    category: "sikkim",
    subcategory: "wholesale",
  },
  {
    title: "White Tea",
    img: whitetea,
    id: 13,
    size1: "125",
    size2: "250",
    slug: "sikkiwhite",
    productname: "White Tea Packet",
    category: "sikkim",
    subcategory: "wholesale"
  },

  {
    title: "First Flush",
    img: FIRSTFLUSH,
    id: 70,
    size1: "125",
    size2: "250",
    slug: "sikkiblack",
    productname: "First tea Packet",
    category: "sikkim",
    subcategory: "wholesale"
  },
  {
    title: "Black tea",
    img: BLACKTEA,
    id: 2,
    size1: "125",
    size2: "250",
    slug: "sikkiblack",
    productname: "Black tea Packet",
    category: "sikkim",
    subcategory: "wholesale"
  },
  {
    title: "Green tea",
    img: GREENTEA,
    id: 51,
    size1: "125",
    size2: "250",
    slug: "greentea",
    productname: "Green tea",
    category: "sikkim",
    subcategory: "wholesale"
  },
  {
    title: "Dust Tea",
    img: DUST,
    id: 4,
    size1: "Dust Tea - 125",
    size2: "Dust Tea - 250",
    slug: "dust_tea",
    productname: "Dust Tea",
    category: "sikkim",
    subcategory: "wholesale",
  },
  {
    title: "Oolong Tea",
    img: ooglongtea,
    id: 12,
    size1: "125",
    size2: "250",
    slug: "sikkioolong",
    productname: "Oolong Tea Packet",
    category: "sikkim",
    subcategory: "wholesale"
  },





  // --------------------------------------- empty ids spotted - 16
  // ---------------------------------------
  // REGULAR SIKKIM ITEMS


  {
    title: "Spring Caddy",
    // img: springcaddy,'
    img: spring,
    id: 11,
    size1: "Spring Caddy - 125",
    size2: "Spring Caddy - 250",
    slug: "spring_caddy",
    productname: "Spring Caddy",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Summer Caddy",
    img: sum,
    id: 1,
    size1: "Summer Caddy - 125",
    size2: "Summer Caddy - 250",
    slug: "summer_caddy",
    productname: "Summer Caddy",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Monsoon Caddy",
    img: mon,
    id: 7,
    size1: "Monsoon Caddy - 125",
    size2: "Monsoon Caddy - 250",
    slug: "monsoon_caddy",
    productname: " Monsoon Caddy",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Autumn Caddy",
    img: aut,
    id: 9,
    size1: "Autumn Caddy - 125",
    size2: "Autumn Caddy - 250",
    slug: "autumn_caddy",
    productname: "Autumn Caddy",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Black Dip",
    img: blackdips,
    id: 19,
    size1: "Black Dip - 125",
    size2: "Black Dip - 250",
    slug: "black_dip",
    productname: "Black Dip",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Green Dip",
    img: greendips,
    id: 20,
    size1: "Green Dip - 125",
    size2: "Green Dip - 250",
    slug: "green_dip_tea",
    productname: "Green Dip",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Oolong Dip",
    img: temioolongdips,
    id: 21,
    size1: "Oolong Dip - 125",
    size2: "Oolong Dip - 250",
    slug: "oolong_dip",
    productname: "Oolong Dip",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Lemon Dips",
    img: lemondips,
    id: 55,
    size1: "Lemon Dips - 125",
    size2: "Lemon Dips - 250",
    slug: "lemon_dips",
    productname: "Lemon Dips",
    category: "sikkim",
    subcategory: "regular",
  },
  {
    title: "Ginger Dips",
    img: gingerdips,
    id: 56,
    size1: "Ginger Dips - 125",
    size2: "Ginger Dips - 250",
    slug: "ginger_dips",
    productname: "Ginger Dips",
    category: "sikkim",
    subcategory: "regular",
  },
  {
    title: "White Tea Dips",
    img: whiteteabox,
    id: 57,
    size1: "White Tea Dips - 125",
    size2: "White Tea Dips - 250",
    slug: "white_tea_dips",
    productname: "White Tea Dips",
    category: "sikkim",
    subcategory: "regular",
  },
  {
    title: "Temi Tea Oolong",
    img: ooglongtea2,
    id: 24,
    size1: "Temi Tea Oolong - 125",
    size2: "Temi Tea Oolong - 250",
    slug: "temitea_oolong",
    productname: "Temi Tea Oolong",
    category: "sikkim",
    subcategory: "regular"
  },

  {
    title: "Silver Needles",
    img: silverneedles,
    id: 54,
    size1: "Silver Needles - 125",
    size2: "Silver Needles - 250",
    slug: "silver_needles",
    productname: "Silver Needles",
    category: "sikkim",
    subcategory: "regular",
  },
  {
    title: "Green Tea Packet",
    img: grntea,
    id: 5,
    size1: "Green Tea Packet - 125",
    size2: "Green Tea Packet - 250",
    slug: "green_tea_packet",
    productname: "Green Tea Packet",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Green Tea Wooden Caddy",
    img: grnwoodcaddy,
    id: 6,
    size1: "Green Tea Wooden Caddy - 125",
    size2: "Green Tea Wooden Caddy - 250",
    slug: "green_tea_wooden_caddy",
    productname: "Green Tea Wooden Caddy",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Temi Tea Awaken",
    img: awak,
    id: 10,
    size1: "Temi Tea Awaken - 125",
    size2: "Temi Tea Awaken - 250",
    slug: "temitea_awaken",
    productname: "Temi Tea Awaken",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Temi Tea First Flush Packet",
    img: firstflushpacket,
    id: 14,
    size1: "Temi Tea First Flush Packet - 125",
    size2: "Temi Tea First Flush Packet - 250",
    slug: "temitea_first_flush_packet",
    productname: "Sikkim Temi Tea First Flush Packet",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Temi Tea Special Packet",
    img: special,
    id: 15,
    size1: "Temi Tea Special Packet - 125",
    size2: "Temi Tea Special Packet - 250",
    slug: "temitea_special_packet",
    productname: "Sikkim Temi Tea Special Packet",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Wooden Caddy Black",
    img: woodencaddyblk,
    id: 17,
    size1: "Wooden Caddy Black - 125",
    size2: "Wooden Caddy Black - 250",
    slug: "wooden_caddy_black",
    productname: "Wooden Caddy Black",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Temi Tea Broken Packet",
    // img: broknpackt,
    img: broken,
    id: 3,
    size1: "Temi Tea Broken Packet - 125",
    size2: "Temi Tea Broken Packet - 250",
    slug: "temitea_broken_packet",
    productname: "Sikkim Temi Tea Broken Packet",
    category: "sikkim",
    subcategory: "regular"
  },
  {
    title: "Dust Pouch",
    img: dustpouch,
    id: 18,
    size1: "Dust Pouch - 125",
    size2: "Dust Pouch - 250",
    slug: "dust_pouch",
    productname: "Green Tea Dust Pouch",
    category: "sikkim",
    subcategory: "regular"
  },


  






  // {
  //   title: "Two Season Souvenir Box",
  //   img: placeholdersoon,
  //   id: 22,
  //   size1: "Two Season Souvenir Box - 125",
  //   size2: "Two Season Souvenir Box - 250",
  //   slug: "two_season_souvenir_box",
  //   productname: "Two Season Souvenir Box",
  //   category: "sikkim",
  //   subcategory: "regular"
  // },
  // {
  //   title: "Four Season Gift Box",
  //   img: placeholdersoon,
  //   id: 23,
  //   size1: "Four Season Gift Box - 125",
  //   size2: "Four Season Gift Box - 250",
  //   slug: "four_season_gift_box",
  //   productname: "Four Season Gift Box",
  //   category: "sikkim",
  //   subcategory: "regular"
  // },




  // DARJEELING TEA LIST BELOW
  {
    title: "Darjeeling First Flush Tea",
    img: p25,
    id: 25,
    size1: "Darjeeling First Flush Tea - 125",
    size2: "Darjeeling First Flush Tea - 250",
    slug: "darjeeling_first_flush_tea",
    productname: "Darjeeling First Flush Tea",
    category: "darjeeling",
    subcategory: "black",
  },
  {
    title: "Second Flush – Black Tea",
    img: p26,
    id: 26,
    size1: "Second Flush – Black Tea - 125",
    size2: "Second Flush – Black Tea - 250",
    slug: "second_flush_black_tea",
    productname: "Second Flush – Black Tea",
    category: "darjeeling",
    subcategory: "black",
  },
  {
    title: "Roasted Darjeeling Black Tea",
    img: p27,
    id: 27,
    size1: "Roasted Darjeeling Black Tea - 125",
    size2: "Roasted Darjeeling Black Tea - 250",
    slug: "roasted_darjeeling_black_tea",
    productname: "Roasted Darjeeling Black Tea",
    category: "darjeeling",
    subcategory: "black",
  },
  {
    title: "Autumn Flush – Black Tea",
    img: p28,
    id: 28,
    size1: "Autumn Flush – Black Tea - 125",
    size2: "Autumn Flush – Black Tea - 250",
    slug: "autumn_flush_black_tea",
    productname: "Autumn Flush – Black Tea",
    category: "darjeeling",
    subcategory: "black",
  },

  {
    title: "Darjeeling First Flush – Green Tea",
    img: p40,
    id: 40,
    size1: "Darjeeling First Flush – Green Tea - 125",
    size2: "Darjeeling First Flush – Green Tea - 250",
    slug: "darjeeling_first_flush_green_tea",
    productname: "Darjeeling First Flush – Green Tea",
    category: "darjeeling",
    subcategory: "green",
  },
  {
    title: "Second Flush – Green Tea",
    img: p41,
    id: 41,
    size1: "Second Flush – Green Tea - 125",
    size2: "Second Flush – Green Tea - 250",
    slug: "second_flush_green_tea",
    productname: "Second Flush – Green Tea",
    category: "darjeeling",
    subcategory: "green",
  },
  {
    title: "Monsoon Flush - Green Tea",
    img: p42,
    id: 42,
    size1: "Monsoon Flush - Green Tea - 125",
    size2: "Monsoon Flush - Green Tea - 250",
    slug: "monsoon_flush_green_tea",
    productname: "Monsoon Flush - Green Tea",
    category: "darjeeling",
    subcategory: "green",
  },
  {
    title: "Autumn Flush – Green Tea",
    img: p43,
    id: 43,
    size1: "Autumn Flush – Green Tea - 125",
    size2: "Autumn Flush – Green Tea - 250",
    slug: "autumn_flush_green_tea",
    productname: "Autumn Flush – Green Tea",
    category: "darjeeling",
    subcategory: "green",
  }
  ,


  {
    title: "Chamomile Tea",
    img: p29,
    id: 29,
    size1: "Chamomile Tea - 125",
    size2: "Chamomile Tea - 250",
    slug: "chamomile_tea",
    productname: "Chamomile Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Kashmiri Kahwa Tea",
    img: p30,
    id: 30,
    size1: "Kashmiri Kahwa Tea - 125",
    size2: "Kashmiri Kahwa Tea - 250",
    slug: "kashmiri_kahwa_tea",
    productname: "Kashmiri Kahwa Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Hibiscus Tea",
    img: p31,
    id: 31,
    size1: "Hibiscus Tea - 125",
    size2: "Hibiscus Tea - 250",
    slug: "hibiscus_tea",
    productname: "Hibiscus Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Tangy Orange Tea | Darjeeling Earl Grey",
    img: p32,
    id: 32,
    size1: "Tangy Orange Tea | Darjeeling Earl Grey - 125",
    size2: "Tangy Orange Tea | Darjeeling Earl Grey - 250",
    slug: "tangy_orange_tea_darjeeling_earl_grey",
    productname: "Tangy Orange Tea | Darjeeling Earl Grey",
    category: "darjeeling",
    subcategory: "wellness",
  },

  {
    title: "Gondhoraj Lemon Honey Tea",
    img: p34,
    id: 34,
    size1: "Gondhoraj Lemon Honey Tea - 125",
    size2: "Gondhoraj Lemon Honey Tea - 250",
    slug: "gondhoraj_lemon_honey_tea",
    productname: "Gondhoraj Lemon Honey Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Rose Tea",
    img: rosetea,
    id: 50,
    size1: "Rose Tea - 125",
    size2: "Rose Tea - 250",
    slug: "rose_tea",
    productname: "Rose Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Lavender Tea",
    img: p35,
    id: 35,
    size1: "Lavender Tea - 125",
    size2: "Lavender Tea - 250",
    slug: "lavender_tea",
    productname: "Lavender Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Jasmine Tea",
    img: p36,
    id: 36,
    size1: "Jasmine Tea - 125",
    size2: "Jasmine Tea - 250",
    slug: "jasmine_tea",
    productname: "Jasmine Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Blue Pea Tea",
    img: bluepea,
    id: 48,
    size1: "Blue Pea Tea - 125",
    size2: "Blue Pea Tea - 250",
    slug: "blue_pea_tea",
    productname: "Blue Pea Tea",
    category: "darjeeling",
    subcategory: "wellness",
  },
  {
    title: "Marigold Ginger",
    img: marigoldtea,
    id: 49,
    size1: "Marigold Ginger - 125",
    size2: "Marigold Ginger - 250",
    slug: "marigold_ginger",
    productname: "Marigold Ginger",
    category: "darjeeling",
    subcategory: "wellness",
  },


  {
    title: "Darjeeling Gold - Roasted Milk Tea",
    img: p39,
    id: 39,
    size1: "Darjeeling Gold - Roasted Milk Tea - 125",
    size2: "Darjeeling Gold - Roasted Milk Tea - 250",
    slug: "darjeeling_gold_roasted_milk_tea",
    productname: "Darjeeling Gold - Roasted Milk Tea",
    category: "darjeeling",
    subcategory: "speciality",
  },
  {
    title: "MoonLight Sonata White Tea",
    img: p37,
    id: 37,
    size1: "MoonLight Sonata White Tea - 125",
    size2: "MoonLight Sonata White Tea - 250",
    slug: "moonlight_sonata_white_tea",
    productname: "MoonLight Sonata White Tea",
    category: "darjeeling",
    subcategory: "speciality",
  },
  {
    title: "Kukicha - Japanese Twig Tea",
    img: p33,
    id: 33,
    size1: "Kukicha - Japanese Twig Tea - 125",
    size2: "Kukicha - Japanese Twig Tea - 250",
    slug: "kukicha_japanese_twig_tea",
    productname: "Kukicha - Japanese Twig Tea",
    category: "darjeeling",
    subcategory: "speciality",
  },
  {
    title: "Darjeeling Cold Brew - Next gen Darjeeling tea - Iced tea",
    img: p38,
    id: 38,
    size1: "Darjeeling Cold Brew - Next gen Darjeeling tea - Iced tea - 125",
    size2: "Darjeeling Cold Brew - Next gen Darjeeling tea - Iced tea - 250",
    slug: "darjeeling_cold_brew_next_gen_iced_tea",
    productname: "Darjeeling Cold Brew - Next gen Darjeeling tea - Iced tea",
    category: "darjeeling",
    subcategory: "speciality",
  },


  {
    title: "Roasted Darjeeling Tea",
    img: easybrewbox,
    id: 44,
    size1: "Roasted Darjeeling Tea - 125",
    size2: "Roasted Darjeeling Tea - 250",
    slug: "roasted_darjeeling_tea",
    productname: "Roasted Darjeeling Tea",
    category: "darjeeling",
    subcategory: "easy brew boxes",
  },
  {
    title: "Wellness Range Assorted Flavoured Teas",
    img: easybrewbox,
    id: 45,
    size1: "Wellness Range Assorted Flavoured Teas - 125",
    size2: "Wellness Range Assorted Flavoured Teas - 250",
    slug: "wellness_range_assorted_flavoured_teas",
    productname: "Wellness Range Assorted Flavoured Teas",
    category: "darjeeling",
    subcategory: "easy brew boxes",
  },
  {
    title: "Green Tea",
    img: easybrewbox,
    id: 46,
    size1: "Green Tea - 125",
    size2: "Green Tea - 250",
    slug: "green_tea",
    productname: "Green Tea",
    category: "darjeeling",
    subcategory: "easy brew boxes",
  },
  {
    title: "Darjeeling Cold Brew",
    img: easybrewbox,
    id: 47,
    size1: "Darjeeling Cold Brew - 125",
    size2: "Darjeeling Cold Brew - 250",
    slug: "darjeeling_cold_brew",
    productname: "Darjeeling Cold Brew",
    category: "darjeeling",
    subcategory: "easy brew boxes",
  },





  // reorder


  // ASSAM HIDDEN

  // {
  //     title: "Assam ice tea",
  //     img: darjeelingimg,
  //     id: 20,
  //     size1: "125",
  //     size2: "250",
  //     slug: "assamicetea",
  //     productname: "Assam Ice tea Broken Packet",
  //     category: "assam"
  // },
]
















