import React, { useState } from 'react'
import logo from '../assets/Logo2.png'
import { FaRegEnvelope, FaMobileAlt, FaWhatsapp, FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Link } from "react-scroll";
export default function Navbar() {
    const [showlinks, setShowlinks] = useState(false)
    return (
        <div className='navbar mn3'>
            <div className="left">
                <a href="/">
                    <img src={logo} alt="logo" className='logoimg' />
                </a>

                <div className="navlinks">

                    <a href='/'>Home</a>
                    <a href="/category/darjeeling">Products</a>

                    <a href='/about'>About us</a>
                    {/* <a href='/blog'>Blog</a> */}

                    {/* <a href="">
                        <Link
                            activeClass="active"
                            to="products"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={1000}
                        >Products</Link>

                    </a> */}
                    <a href=''>
                        <Link
                            activeClass="active"
                            to="footer"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={1000}
                        >Contact us
                        </Link>
                    </a>
                </div>
            </div>
            <div className="right">
                <IconContext.Provider value={{ size: "1.2rem" }}>
                <a href="mailto:teaasiacom@gmail.com">
                    <div className="cntsvgtxt">
                        <div className="ico"><FaRegEnvelope /></div>
                        <div className="txt">teaasiacom@gmail.com</div>
                        </div>
                        </a>

                    <div className="cntsvgtxt">
                        <div className="ico">
                            <FaWhatsapp />
                        </div>
                        <a className="txt" href="https://wa.me/918250195857?text=Regarding%20services%20of%20tea%20asia,%20
">
                            whatsapp-us-here
                        </a>
                    </div>
                </IconContext.Provider>
            </div>
            <div className="bar" onClick={() => setShowlinks(!showlinks)}>
                <IconContext.Provider value={{ size: "1.4rem" }}>
                    <FaBars />
                    {showlinks && <div className="navlinks">

                        <a href='/'>Home</a>
                        <a href="/category/darjeeling">
                            {/* <Link
                                activeClass="active"
                                to="products"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={1000}
                            >
                            Products
                            </Link> */}
                            Products
                        </a>
                        <a href='/about'>About us</a>


                        <a href=''>
                            <Link
                                activeClass="active"
                                to="footer"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={1000}
                            >Contact us
                            </Link>
                        </a>

                    </div>
                    }
                </IconContext.Provider>

            </div>
        </div>
    )
}
