import React from 'react'
// import Navbar2 from '../Navbar2';
import Navbar from '../Navbar';

import Footer from '../Footer'

export default function About() {
    return (
        <>
            <Navbar />
            <div className='about'>
                <div className="area">
                    <h2 className='title mn4' id='products'>About us</h2>
                    <p className="abouttxt mn3">
                        Welcome to Tea-Asia! We are a team of passionate tea lovers from Tibet and Germany who are dedicated to introducing the world to the amazing flavors and aromas of teas from all around the world.
                        <span className="br"></span>
                        We believe that everyone should have access to the best teas the world has to offer. That's why we take special pride in offering not only the most popular teas from Darjeeling and other countries but also rare, exotic teas from all corners of the world.
                        <span className="br"></span>
                        We are especially proud of our selection of high-end teas from Darjeeling, where our team members are from. These teas are not only incredibly flavorful and fragrant but also steeped in history and culture.
                        <span className="br"></span>
                        At Tea-Asia, we strive to provide the highest quality teas at the best prices. Our team is constantly on the lookout for new and exciting teas to add to our collection.<span className="br"></span>
                        We are confident that with our knowledge and passion, we will be able to provide you with the perfect cup of tea that will make you feel relaxed and enjoy your tea drinking experience to the fullest. Thank you for visiting us and we look forward to serving you!<span className="br"></span>
                    </p>
                </div>





            </div>

            <Footer />
        </>
    )
}
