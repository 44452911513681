import React from 'react'
import { IconContext } from 'react-icons'
import { FaWhatsapp } from 'react-icons/fa'
import ScrollToTop from '../helpers/ScrollToTop'

export default function Whatsappwidget() {
    return (
        <>
        <ScrollToTop />
        <a href='https://wa.me/918250195857?text=Regarding%20services%20of%20tea%20asia,%20' className='whatsappwidget'>
            <IconContext.Provider value={{ size: "2.4rem" }}>

                <FaWhatsapp />
            </IconContext.Provider>
        </a>
        </>
    )
}
