import React, { useState } from 'react';
import sikkimbanner from '../../assets/bg/sikkimbanner.jpg'
import darjeelingbanner from '../../assets/bg/darjeelingbanner.jpg'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { teaproducts } from '../teadata/Teatypedata';
import Whatsappwidget from '../tools/Whatsappwidget';
import { CgArrowLongLeftR, CgArrowLongRightR } from 'react-icons/cg';
import { FaCheckCircle, FaLeaf } from 'react-icons/fa';
import { motion } from "framer-motion";

const Sidebar = ({ category, currentcategory, subcategories, subcategoriesSikkim, selectedSubcategories, handleSubcategorySelection }) => {

    const countItemsBySubcategory = (subcategory) => {
        return currentcategory.filter((product) => product.subcategory === subcategory).length;
    };

    let currentSubcatlist;

    if (category === 'darjeeling') {
        currentSubcatlist = subcategories;
    } else if (category === 'sikkim') {
        currentSubcatlist = subcategoriesSikkim;
    } else {
        currentSubcatlist = [];
    }
    return (
        <>
            <motion.div className="sidebar sbarpc"
                initial={{ y: 0 }}
                transition={{ type: "spring", damping: 25, stiffness: 100 }}

            >

                <div className="topsdtxt mn4">filter our tea types <FaLeaf /></div>
                {currentSubcatlist.map((subcategory) => (
                    <div
                        className={
                            selectedSubcategories.includes(subcategory) ? 'subcategory active' : 'subcategory'
                        } onClick={() =>
                            countItemsBySubcategory(subcategory) > 0
                                ? handleSubcategorySelection(subcategory)
                                : null
                        } key={subcategory}>
                        <div
                            className="head mn2"

                        >
                            {subcategory}
                        </div>
                        <div className="totalsub mn2">
                            {countItemsBySubcategory(subcategory)}
                        </div>
                    </div>
                ))}
            </motion.div>




            <div className="sidebar sbarmobile"
            >

                <div className="topsdtxt mn4">filter our tea types <FaLeaf /></div>
                {currentSubcatlist.map((subcategory) => (
                    <div
                        className={
                            selectedSubcategories.includes(subcategory) ? 'subcategory active' : 'subcategory'
                        } onClick={() =>
                            countItemsBySubcategory(subcategory) > 0
                                ? handleSubcategorySelection(subcategory)
                                : null
                        } key={subcategory}>
                        <div
                            className="head mn2"

                        >
                            {subcategory}
                        </div>
                        <div className="totalsub mn2">
                            {countItemsBySubcategory(subcategory)}
                        </div>
                    </div>
                ))}
            </div>
        </>



    );
};


export default function Tealist() {
    const { category } = useParams();
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);

    // const subcategories = ['green', 'black', 'oolong', 'white', 'wellness', 'speciality', 'easy brew boxes'];
    const subcategories = ['black', 'green', 'wellness', 'speciality', 'easy brew boxes'];

    const subcategoriesSikkim = ['wholesale', 'regular']
    const currentcategory = teaproducts.filter((cat) => cat.category === category);

    const handleSubcategorySelection = (subcategory) => {
        if (selectedSubcategories.includes(subcategory)) {
            setSelectedSubcategories((prevSelectedSubcategories) =>
                prevSelectedSubcategories.filter((item) => item !== subcategory)
            );
        } else {
            setSelectedSubcategories((prevSelectedSubcategories) => [
                ...prevSelectedSubcategories,
                subcategory,
            ]);
        }
    };




    return (
        <>
            <Navbar />
            {console.log(currentcategory)}
            <div className='tealist mn2'>
                <div className="currentarea area2">
                    {/* <a href="../">Home&nbsp;</a> */}
                    {'>'} Products {'>'} tea {'>'} {category}
                </div>


                <div className="teacategorysidebarcnt">

                    <Link to={`/category/darjeeling`}>
                        <div className={category === 'darjeeling' ? "categorycnt activecat" : "categorycnt"}>
                            {category === "darjeeling" &&
                                <div className="toprighticon">

                                    <FaCheckCircle size="1.75rem" />
                                </div>
                            }

                            <img src={darjeelingbanner} alt="darjeelingbanner" className='categorybanner ' />
                        </div>
                    </Link>

                    <div className="selecttxt webonly mn3">

                        <div className="p mn3">select category</div>
                        <div className="arrows">
                            <CgArrowLongLeftR className='left-arrow' />
                            <CgArrowLongRightR className='right-arrow' />
                        </div>

                    </div>

                    <div className="selecttxt mobileonly mn3">

                        <div className="arrows">
                            <CgArrowLongLeftR className='left-arrow' />
                        </div>

                        <div className="p mn3">select category</div>

                        <div className="arrows">
                            <CgArrowLongLeftR className='right-arrow' />
                        </div>

                    </div>

                    <Link to={`/category/sikkim`}>
                        <div className={category === 'sikkim' ? "categorycnt activecat" : "categorycnt"}>

                            {category === "sikkim" &&
                                <div className="toprighticon">

                                    <FaCheckCircle size="1.75rem" />
                                </div>
                            }

                            <img src={sikkimbanner} alt="sikkimbanner"
                                className='categorybanner ' />
                        </div>
                    </Link>
                </div>


                <div className="tealistparentcnt">

                    <div className="tealistcnt">

                        <div className="sidebarcnt">
                            <Sidebar
                                category={category}
                                currentcategory={currentcategory}
                                subcategories={subcategories}
                                subcategoriesSikkim={subcategoriesSikkim}
                                selectedSubcategories={selectedSubcategories}
                                handleSubcategorySelection={handleSubcategorySelection}
                            />

                        </div>

                        <div className="teatypes">

                            <div className="teasubcatarea">
                                {
                                    // Create groups of items by subcategory
                                    Object.entries(
                                        currentcategory
                                            .filter(({ subcategory }) => selectedSubcategories.length === 0 || selectedSubcategories.includes(subcategory))
                                            .reduce((groups, item) => {
                                                const subcategory = item.subcategory;
                                                if (!groups[subcategory]) {
                                                    groups[subcategory] = [];
                                                }
                                                groups[subcategory].push(item);
                                                return groups;
                                            }, {})
                                    ).map(([subcategory, items]) => (

                                        <div key={subcategory}>
                                            <div className="subcategoryhd mn4">
                                                {subcategory}
                                            </div>

                                            <div className="teacategorycnt teacategorycnt--B mn3" id="products">
                                                {items.map(({ img, title, id, slug }) => (
                                                    <Link to={`${slug}`} className="teaboxnu" id={id} key={id}>
                                                        <img src={img} className="teacategoryimg" alt="tea" />
                                                        <p className="teacattitle">{title}</p>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>


                    </div>
                </div>

                <Whatsappwidget />
            </div>
            <Footer />
        </>
    );
}
