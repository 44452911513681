import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar'
import Footer from '../Footer'


export default function About() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
          try {
            const response = await fetch('https://your-wordpress-site/wp-json/wp/v2/posts');
            const data = await response.json();
            setPosts(data);
          } catch (error) {
            console.error('Error fetching blog posts:', error);
          }
        };
    
        fetchPosts();
      }, []);

      
    return (
        <>
            <Navbar />
            
            <div>
          {posts.map((post) => (
            <div key={post.id}>
              <h2>{post.title.rendered}</h2>
              <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
            </div>
          ))}
        </div>

            <Footer />
        </>
    )
}
